import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"


export default function BlogPosts({ showPostsCount=false}) {
  /** 
  * minimalBlogPostsCount   
   * The minimal blog posts count in order to display it in the UI 
   */
  const minimalBlogPostsCount = 2;
   /**
   * The number of test blog posts that don't count because they're hidden in production env.
   */
  const testBlogPostNoCount = 1;
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              date
              path
              developmentMode
            }
            excerpt
          }
        }
      }
    }
  `)
  return (
      <>
        {showPostsCount && data && data.allMarkdownRemark && data.allMarkdownRemark.totalCount >= minimalBlogPostsCount && (<h4>{data.allMarkdownRemark.totalCount - testBlogPostNoCount} Поста</h4>)}
        {data.allMarkdownRemark.edges.map(({ node }) => {
          if(node.frontmatter.developmentMode) {
            return
          }
          return (
          <div key={node.id}>
            <Link className="text-decoration-none text-secondary"
              to={`/blog/${node.frontmatter.path}/`}
            >
              <h3
              >
                {node.frontmatter.title}{" "}
              </h3>
              <p>{node.excerpt}</p>
            </Link>
          </div>
        )}
        )}
      </>
  )
}