import React from "react"
import Layout from "../components/layout"
import BlogPosts from '../components/blogPosts'
import SEO from "../components/seo"

export default function Blog({ data }) {
  return (
    <Layout>
      <SEO title="Блог"/>
      <div className='container'>
        <h1>
          Блог
        </h1>
        <BlogPosts showPostsCount="true"/>
      </div>
    </Layout>
  )
}